<template>
  <div class="home">
    <div id="YCF-top">
      <div class="top-titles">
        <h1>
          <b v-html="ToptTitles.title"></b>
          <br />
          <small v-html="ToptTitles.second_title"></small>
        </h1>
      </div>

      <div class="background-group">
        <div
          v-for="(item, index) in TopCovers"
          :key="item.id"
          class="bg"
          :class="{ active: index == 0 }"
          :style="'background-image: url(' + item + ');background-size:cover'"
        ></div>
      </div>
    </div>

    <section id="YCF-about">
      <div class="about-row row">
        <div class="col-md-6 about-image">
          <img :src="About.img" alt="鼎創工程" title="鼎創工程" />
        </div>
        <div class="col-md-6 about-info">
          <div>
            <header>
              <h1 v-html="About.title"></h1>
              <span v-html="About.second_title"></span>
            </header>
            <p class="content" v-html="About.content"></p>
            <router-link class="more-btn" :to="About.button.url">
              <i class="fas fa-arrow-circle-right"></i>
              {{ About.button.title }}
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section id="YCF-product">
      <div class="row product-row">
        <div v-for="item in Feature" :key="item.id" class="product-card">
          <div class="col-md-6 product-image animation">
            <img
              class
              :src="item.img"
              :alt="'鼎創工程 ' + item.title"
              :title="'鼎創工程 ' + item.title"
            />
          </div>

          <div class="col-md-6 product-info animation">
            <div>
              <header>
                <h1 v-html="item.title"></h1>
                <span v-html="item.second_title"></span>
              </header>
              <p class="content" v-html="item.content"></p>
              <router-link
                v-if="item.button"
                :to="item.button.url"
                class="more-btn animation"
              >
                <i class="fas fa-arrow-circle-right"></i>
                {{ item.button.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js'
export default {
  name: 'home',
  data() {
    return {
      ToptTitles: {
        title: '鼎創工程',
        second_title: '認真、專業、負責、守信',
      },
      TopCovers: ['/img/index-header/home_cover.jpg'],
      NewsList: [],
      Feature: [
        {
          title: '服務與設備',
          second_title: 'SERVICES',
          content:
            '各項工程監測系統規劃分析與評估、安全鑑定作業、工程作業規劃與技術諮詢顧問。',
          button: {
            title: '了解服務與設備',
            url: '/Features',
          },
          img: '/img/index/Advantage/356367_ced7cf14517d4393abd165391b9aad68.jpg',
        },
        {
          title: '工程實績',
          second_title: 'WORKS',
          content: '歷年各項工程案例分享、與實務列表。',
          button: {
            title: '查看工程實績',
            url: '/News/1',
          },
          img: '/img/index/Advantage/achievement-agreement-arms-1068523-1024x670.jpg',
        },
        {
          title: '自動化監測平台',
          second_title: 'AUTOMATED MONITORING',
          content: '即時掌握監測狀態，讓工程進行更加安全。',
          button: {
            title: '前往自動化監測平台',
            url: 'http://104.199.225.155/',
          },
          img: '/img/index/Advantage/top-10-businss-goal-setting-tips.jpg',
        },
      ],
      About: {
        title: '關於鼎創',
        second_title: 'ABOUT DINGCHONG',
        content:
          '本公司成立於民國七十七年八月，二十餘年來，本著穩健、誠實之經營，使公司日漸成長，目前設有工程、業務、管理及研發各部專職員工，並延聘國內之大地工程專家、教授擔任長期顧問，以協助業主處理有關工程問題及提供完善之建議與服務。',
        button: {
          title: '了解更多鼎創工程',
          url: '/About',
        },
        img: '/img/index/About/odm_oem_01.jpg',
      },
      meta_data: null,
    }
  },
  metaInfo() {
    return this.meta_data
  },
  mounted() {
    window.prerenderReady = true
  },
  created() {
    this.meta_data = GetMetaData('', '', '')
  },
}
</script>
